import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Container, Row, Col } from "react-bootstrap";

ReactDOM.render(
    <React.StrictMode>
        <Container>
            <Row>
                <Col>
                    <App />
                </Col>
            </Row>
        </Container>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
